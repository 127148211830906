<template lang="pug">
v-row
  v-col
    input(type="file" @input='uploadImageVue($event)' @click='resetImageUploader' accept="image/*" ref="file" style="display: none")
    v-btn(outlined @click="$refs.file.click()") 
      v-icon() mdi-camera
  v-dialog(v-model='showDialog', max-width='900px', :persistent="loading"  scrollable)
    v-card
      cropper.cropper(
        v-if="type == 'profileImage'"
        ref='cropper',
        :src='img',
        :auto-zoom='false',
        image-class='cropper__image',
        :stencil-component='$options.components.CircleStencil'
      )
      cropper.cropper(
        v-else
        ref='cropper',
        :src='img',
        :auto-zoom='false',
        image-class='cropper__image'
      )

      v-card-actions.absolute
        v-btn(@click='closeCropper', :disabled="loading" color='grey' text) Abbrechen
        v-spacer
        v-btn(@click='cropImage', color='accent', text :disabled="loading" :loading="loading") Zuschneiden
</template>

<script lang="js">
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
export default {
  components: { Cropper, CircleStencil },
  props: ["type"],
  data() {
    return {
      loading: false,
      img: null,
      inputFieldLabel: 'Neues Profilbild hochladen',
      showDialog: false,
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.user.currentUser
    },
    // loading() {
    //   return this.$store.state.user.loading
    // },
  },
  methods: {
    resetImageUploader() {
      this.$refs.file.value = '';
    },
    cropImage() {
      this.loading = true
      const result = this.$refs.cropper.getResult()
      this.img = result.canvas.toDataURL('image/jpeg')
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this

      result.canvas.toBlob(function(blob) {

        if(self.type == 'profileImage') {
          self.uploadProfileImage(blob)
        }else if (self.type == 'companyLogo') {
          self.uploadCompanyLogo(blob)
        }
      })

    },
    uploadProfileImage(blob) {
      const uid = this.currentUser.id;
      this.$store.dispatch('user/uploadProfileImage', {
          userId: uid,
          blob: blob,
        }).then((url)=> {
       this.$emit('onImage', url);
        this.closeCropper();
        })
    },
    uploadCompanyLogo(blob) {
      this.$store.dispatch('business/uploadCompanyLogo', {
          blob: blob,
        }).then((url)=> {this.$emit('onImage', url);
          this.closeCropper();
        })
    },
    closeCropper() {
      this.pictureFromInput = null
      this.showDialog = false
      this.loading = false
    },
    uploadImageVue(event) {
      const file = event.target.files[0];
      this.img = URL.createObjectURL(file)
      if (file != null) {
        this.showDialog = true
      } else {
        this.showDialog = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.absolute
  position: absolute
  bottom: 0
  right: 0
  left: 0
  background: #fff
  width: 100%
  border-radius: 0 0 4px 4px !important
.cropper
  // min-height: 400px
  margin-bottom: 80px
</style>
