<template lang="pug">
v-item-group(@change="changed" :value="currentValue")
    v-row

      v-col.px-2(
        v-for='button in data',
        :key='button.title',
        cols='12',
        :md='12 / data.length'
      )
        v-item(v-slot='{ active, toggle }')
          .px-1.py-2.d-flex.align-center.gender-card(
            height='50',
            @click='!disabled ? toggle() : null'
          )
            v-row
              v-col(cols='3')
                v-icon.gender-tick-icon(v-if='active') $tick
              v-col.d-flex.justify-center.align-center(cols='6')
                v-icon.gender-icon(v-if='button.icon', color='$accent') {{ button.icon }}
                span {{ button.title }}
</template>

<script>
export default {
  props: ['data', 'disabled', 'currentValue'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    changed(index) {
      this.$emit('setSelection', this.data[index]);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.gender-card
  border: solid $light-gray 2px !important
  border-radius: 25px !important
  box-shadow: none !important
  cursor: pointer

.gender-card.v-item--active
  border: solid $accent 2px !important

.gender-icon
  margin-right: 10px

.gender-tick-icon
  margin: 0 0 0 20px
</style>
